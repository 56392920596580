import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from 'vue-router';
import { useCheckRouteStore } from '../stores/route';
import {
  home,
  mainPage,
  main,
  apply,
  userList,
  privacy,
  reservation,
  manager,
  managerSignup,
  badge,
  terms,
  managerLayout,
  mainMobile,
  userDetail,
  buyForm,
  login,
  mainDesktop,
  niceAuth,
  submitDone,
  landingPage,
} from './routePaths';
import {
  MainPage,
  UserList,
  BuyForm,
  Login,
  LandingPage,
  SubmitDone,
} from '@views';
import {
  checkAdmin,
  checkToken,
} from '@utils/authentication';
import {
  routeHelper,
  authentication,
} from '@utils';
import {
  DefaultLayout,
  ManagerLayout,
} from '@layouts';

const routes: Array<RouteRecordRaw> = [
  {
    path: home,
    name: routeHelper.getRouteName(home),
    component: LandingPage,
  },
  {
    path: managerLayout,
    name: routeHelper.getRouteName(managerLayout),
    component: ManagerLayout,
    children: [{
      path: userList,
      name: routeHelper.getRouteName(userList),
      component: UserList,
    }],
  },

  {
    path: login,
    name: routeHelper.getRouteName(login),
    component: Login,
  },
  {
    path: mainPage,
    name: routeHelper.getRouteName(mainPage),
    component: MainPage,
  },
  {
    path: landingPage,
    name: routeHelper.getRouteName(landingPage),
    component: LandingPage,
  },
  {
    path: buyForm,
    name: routeHelper.getRouteName(buyForm),
    component: BuyForm,
  },
  {
    path: submitDone,
    name: routeHelper.getRouteName(submitDone),
    component: SubmitDone,
  },
];

//

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('terms')?.scroll(0, 0);
  },
});

router.beforeEach(async (to, from, next) => {
  const checkRoute = useCheckRouteStore();

  checkRoute.initRoute(to.path);
  if (to.path === login) {
    await checkToken(next, 'login');
    return;
  }

  if (to.path === home) {
    await checkToken(next, 'home');
    return;
  }

  await checkToken(next, 'apply');
});

// 라우터 정보가 변경 될때마다 호출
// 매 페이지 인증검사 실시
// 참고페이지
// https://router.vuejs.org/kr/api/#router-beforeeach

// router.beforeEach(async (to, from, next) => {
//   if (to.name === 'login' || from.name === 'login') {
//     next();
//   } else {
//     // 쿠키를 체크 합니다.
//     const errorType = await authentication.cookieCheck();
//     // console.log('errorType', errorType);

//     if (errorType === 'success') {
//       next();
//     } else if (errorType === 'refreshTokenRefresh') {
//       // 쿠키가 만료되어서 로그인 페이지로 이동 합니다.
//       next({
//         name: routeHelper.getRouteName(login),
//         params: {
//           refreshTokenEnd: 'refreshTokenEnd',
//         },
//       });
//     } else if (errorType === 'accessTokenRefresh') {
//       // cookieCheck에 의해 accessToken이 재발급 되었으므로, 이동합니다.
//       next();
//     }
//   }
// });

export default router;
