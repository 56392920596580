import { AxiosResponse } from 'axios';
import { PageType } from '@interface/reserve';
import axiosInstance from '@apis/axiosInstance';

/*
1. 사전신청
2. 프로필
3. 지원서
4. 여행
5. 뱃지 */

// 1. 사전신청

/* 사전신청 등록 */
export const postReserve = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('manager/pre-applications', params);
  return res;
};

/* 사전신청 조회 */
export const getReserve = (params: PageType): Promise<AxiosResponse> => {
  const res = axiosInstance.get('manager/pre-applications/list', { params });
  return res;
};

/* 사전신청 수정 */
export const putReserve = (id: number, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put(`manager/pre-applications/${id}`, params);
  return res;
};

// 2. 프로필

/* 프로필 리스트 */
export const getUser = (params: PageType): Promise<AxiosResponse> => {
  const res = axiosInstance.get('auth/profile/list', { params });
  return res;
};

/* 프로필 상세조회 */
export const getUserDetail = (userId: string): Promise<AxiosResponse> => {
  const res = axiosInstance.get(`auth/profile/detail/${userId}`);
  return res;
};

/* 지원서 리스트 조회 with page */
export const getApplication = (params: PageType): Promise<AxiosResponse> => {
  const res = axiosInstance.get('applications/list', { params });
  return res;
};

/* 뱃지심사 조회 with page */
export const getBadge = (params: PageType): Promise<AxiosResponse> => {
  const res = axiosInstance.get('manager/badge-request/list', { params });
  return res;
};

/* 뱃지심사 - 승인 or 거절 */
export const patchBadge = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.patch('manager/badge-request/confirm', params);
  return res;
};

/* 포인트 등록 및 차감 multi */
export const postPoint = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('manager/points/multi', params);
  return res;
};

/* 둘라밤 여행 리스트 with page */
export const getTravel = (params: PageType): Promise<AxiosResponse> => {
  const res = axiosInstance.get('manager/dullabam/list', { params });
  return res;
};

/* 둘라밤 여행 등록 */
export const postTravel = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.post('/manager/dullabam', params);
  return res;
};

/* 둘라밤 여행 수정 */
export const putTravel = (id: number, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put(`manager/dullabam/${id}`, params);
  return res;
};

/* 둘라밤 여행 마감 */
export const patchFinishTravel = (id: number): Promise<AxiosResponse> => {
  const res = axiosInstance.patch(`manager/dullabam/close/${id}`);
  return res;
};

/* [메모] 매니저 운영용 - 변경 */
export const putMemo = (userId: string, params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put(`auth/profile/memo/${userId}`, params);
  return res;
};

/* 사전신청 삭제 [멀티] */
export const deleteApplication = (data: any): Promise<AxiosResponse> => {
  const res = axiosInstance.delete('/manager/pre-applications/multi', { data });
  return res;
};

/* 둘라밤 여행 정보 */
export const getTravelDetail = (id: number): Promise<AxiosResponse> => {
  const res = axiosInstance.get(`manager/dullabam/${id}`);
  return res;
};

// 지원서 동시 수정 - 상태변경, 둘라밤 여행 매칭 (여러 개)
export const putApplication = (params: any): Promise<AxiosResponse> => {
  const res = axiosInstance.put('/applications/multiple', params);
  return res;
};
