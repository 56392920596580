import { defineStore } from 'pinia';

// 회원 정보
export const userStore = defineStore('user', {
  state: () => ({
    userId: '',
    userRole: '',
  }),
  persist: { storage: localStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});

export const testResultStore = defineStore('result', {
  state: () => ({
    result: '',
    userRole: '',
  }),
  persist: { storage: localStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});

// 현재 섹션, 페이지 정보
export const pageStore = defineStore('page', {
  state: () => ({
    current: 1,
    pageSize: 10,
    section: 0,
    loading: false,
  }),
  persist: { storage: sessionStorage }, // pinia-plugin-persistedstate 사용 : 새로고침시 데이터 유지
});
