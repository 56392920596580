export const home = '/';
export const managerLayout = '/managerLayout';
export const inquiryInput = '/inquiryInput';
export const infoadd = '/infoadd';
export const history = '/history';
export const layoutExample = '/layoutExample';
export const mainlayout = '/mainlayout';
export const mainPage = '/mainPage';
export const main = '/main';
export const layoutManager = '/layoutManager';
export const userList = '/userList';
export const apply = '/apply';
export const privacy = '/privacy';
export const reservation = '/reservation';
export const manager = '/manager';
export const managerSignup = '/managerSignup';
export const badge = '/badge';
export const terms = '/terms';
export const mainMobile = '/mainMobile';
export const mainDesktop = '/mainDesktop';
export const userDetail = '/userDetail';
export const travel = '/travel';
export const landingPage = '/landingPage';
export const niceAuth = '/niceAuth';
export const buyForm = '/buyForm';
export const login = '/login';
export const signup = '/signup';
export const submitDone = '/submitDone';

export default {
  signup,
  login,
  home,
  mainPage,
  layoutExample,
  mainlayout,
  inquiryInput,
  history,
  infoadd,
  main,
  managerLayout,
  userList,
  apply,
  privacy,
  reservation,
  manager,
  managerSignup,
  badge,
  terms,
  mainMobile,
  mainDesktop,
  userDetail,
  travel,
  landingPage,
  niceAuth,
  buyForm,
  submitDone,
};
