import axios, {
  AxiosError,
  AxiosInstance,
} from 'axios';
import { userStore } from '../stores/storeAuth';
import useSpinnerStore from '../stores/spinnerStore';
import {
  DEV_URL,
  CARD_URL,
} from './endpoints';
import { requestPostRefreshToken } from './common';
import {
  deleteStorage,
  getStorage,
  setStorage,
} from '@utils/storageUtils';
import { pushPage } from '@utils/route.helper';
import { errorResponseType } from '@interface/error';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: DEV_URL,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
});

const axiosInstanceCard: AxiosInstance = axios.create({
  baseURL: CARD_URL,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
});

export const axiosTokenInstance: AxiosInstance = axios.create({
  baseURL: DEV_URL,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
});

export const axiosTokenInstanceCard: AxiosInstance = axios.create({
  baseURL: CARD_URL,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
});

let isTokenRefreshing = false;
// const refreshSubscribers: AccumulatedRequestType[] = [];

// const onTokenRefreshed = (accessToken: string) => {
//   refreshSubscribers.map((callback) => callback(accessToken));
// };

axiosInstance.interceptors.request.use((config) => {
  const spinnerStore = useSpinnerStore();
  if (config.method === 'get') return config;
  spinnerStore.startSpinning();
  return config;
});

// 모든 응답에 대한 리스폰스 관리
axiosInstance.interceptors.response.use(
  (response) => {
    const spinnerStore = useSpinnerStore();
    spinnerStore.endSpinning();
    return Promise.resolve(response);
  },
  async (error) => {
    const spinnerStore = useSpinnerStore();
    spinnerStore.endSpinning();
    /* 추후 액세스 토큰 만료시 액세스 토큰 재발급 후 재요청 하는 로직 */
    const {
      config,
      response: {
        status,
        data,
      },
    } = error;

    const originalRequest = config;
    if (status === 401) {
      if (data.message === 'Unauthorized') {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          const refreshTokenValue = getStorage('refreshToken');
          try {
            const userData = userStore();
            const result = await requestPostRefreshToken(refreshTokenValue ?? '', userData.userId);
            // 토큰 재발급 성공
            const { accessToken } = result.data;
            const { refreshToken } = result.data;

            isTokenRefreshing = false;
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            if (originalRequest.headers !== undefined) {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            }

            setStorage('accessToken', accessToken);
            setStorage('refreshToken', refreshToken);

            // 페이지 새로고침 코드
            // window.location.reload();
          } catch (e) {
            // 토큰 재발급 실패 또는 오류 처리
            if ((e as any).response.data.errorData.errorCode === 'ERR_OAUTH_TOKEN') {
              deleteStorage('accessToken');
              deleteStorage('refreshToken');
              pushPage('login');
            }
          }
        }

        const retryOriginalRequest = new Promise((resolve) => {
          // addRefreshSubscriber((accessToken: string) => {
          //   originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          //   resolve(axios(originalRequest));
          // });
        });
        return retryOriginalRequest;
      }
    }

    const { response } = error as AxiosError<errorResponseType>;
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(response?.data);
  },
);

export default axiosInstance;
